
import Vue, { PropType } from 'vue'
import { Activation } from '@/types/GCB2'
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import { Project } from '../../../../types/main'
import fb from '@/plugins/firebase'
import { setChangedActivationNotification } from '../../../../api/chisai/GCB2'
import Loading from '../../../project/Loading.vue'
export default Vue.extend({
    components: {
        Loading
    },
    props: {},
    data: () => ({
        notificationRef: null as any,
        lastUpdateRef: null as any,
        notification: null as any,
        projectLastUpdate: 0,
        snackbarActive: false as boolean,
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },
    watch: {
        notification() {
            this.snackbarActive = this.notification && this.notification.active
        },
    },
    methods: {
        async closeNotification() {
            this.snackbarActive = false
            await setChangedActivationNotification({
                projectId: this.project.id,
                active: false,
            })
        },
    },
    created() {
        this.notificationRef = fb
            .database()
            .ref(`/project/${this.project.id}/notifcations/GCB2/activationChanged`)
        this.notificationRef.on('value', (data: any) => {
            this.notification = data.val()
        })
    },
    destroyed() {
        this.notificationRef.off()
    },
})
