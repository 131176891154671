
import { computed, defineComponent, ref, PropType, watch, getCurrentInstance } from 'vue'
import { IGCB2Connector, ManualCommunication } from '@/types/GCB2'
import { useGcb2Store } from '../../../../store/stores/gcb2/gcb2'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import taskListActions from '@/components/chisai/GCB2/taskList/actions'
import * as t from '@/store/chisai/GCB2/actionTypes'
import * as _ from 'lodash'
import { TASKLIST_ACTION_TYPES, TASKLIST_COMMUNICATIONS_TYPES } from '../../../../vars/GCB2'
import moment from 'moment'
import { useProject } from '../../../../hooks/useProject'
import CommunicationTagsPicker from './CommunicationTagsPicker.vue'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import { checkModuleIsActive } from '../../../../helpers/roles'
export default defineComponent({
    components: { BaseInputOutlined, CommunicationTagsPicker },
    props: {},
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const gcb2Store = useGcb2Store()
        const { project } = useProject()
        const manualCommunication = computed(() => {
            if (_.isNil(gcb2Store.actionModal.communicationId)) return
            return root.$store.getters[`GCB2/${t.GET_ONE_MANUAL_COMMUNICATION}`](
                gcb2Store.actionModal.communicationId
            )
        })
        const validtationMode = computed(() => {
            const gcb2Connector = project.value.connectors.find(
                el => el.connectorType === CONNECTOR_TYPES.GCB2
            )
            if (!gcb2Connector?.actionModalValidation) return 'tagsOrComment'
            return gcb2Connector.actionModalValidation
        })

        const getComment = () => {
            if (gcb2Store.actionModal.actionType === TASKLIST_ACTION_TYPES.CHANGE_COMMENT) {
                return manualCommunication.value.comment
            }
            return ''
        }
        const tagsIsActive = computed(() =>
            checkModuleIsActive(project.value.id, 'gcb2:tasklist:activationTags')
        )

        const getSelectedCommunicationTags = () => {
            if (gcb2Store.actionModal.actionType === TASKLIST_ACTION_TYPES.CHANGE_COMMENT) {
                return manualCommunication.value.communicationTags
            }
            return []
        }

        const comment = ref('')

        const postponedDate = ref(null)
        const communicationTags = computed(() => gcb2Store.getCommunicationTags(project.value.id))
        const selectedCommunicationTags = ref([])

        const setInitalData = () => {
            comment.value = getComment()
            postponedDate.value = null
            selectedCommunicationTags.value = getSelectedCommunicationTags()
        }

        const closeModal = () => {
            gcb2Store.closeActionModal()
            const form = root.$refs.form as any
            form.resetValidation()
        }

        const actionType = computed(() => gcb2Store.actionModal.actionType)
        const getAllowedPostponeDate = (val: string) => moment(val) > moment().subtract(1, 'day')

        const applyButtonText = computed(() => {
            if (actionType.value === TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE) return 'Отложить'
            return 'Применить'
        })

        const makeAction = async () => {
            switch (actionType.value) {
                case TASKLIST_ACTION_TYPES.CONTACT:
                    taskListActions.contact({
                        vueInstanse: root,
                        item: manualCommunication.value,
                        comment: comment.value,
                        communicationTags: selectedCommunicationTags.value,
                        commType: TASKLIST_COMMUNICATIONS_TYPES.NO
                    })
                    break
                case TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE:
                    taskListActions.changeExpectedDate({
                        vueInstanse: root,
                        comment: comment.value,
                        item: manualCommunication.value,
                        communicationTags: selectedCommunicationTags.value,
                        postponedTo: postponedDate.value!,
                    })
                    break
                case TASKLIST_ACTION_TYPES.DONT_REMIND:
                    taskListActions.dontRemind({
                        vueInstanse: root,
                        item: manualCommunication.value,
                        comment: comment.value,
                        communicationTags: selectedCommunicationTags.value,
                    })
                    break
                case TASKLIST_ACTION_TYPES.CHANGE_COMMENT:
                    taskListActions.changeComment({
                        vueInstanse: root,
                        comment: comment.value,
                        item: manualCommunication.value,
                        communicationTags: selectedCommunicationTags.value,
                    })
                    break
                default:
                    break
            }
            closeModal()
        }

        const isValid = computed(() => {
            let fieldsValid = false
            if (validtationMode.value === 'tagsOrComment') {
                fieldsValid = Boolean(comment.value || selectedCommunicationTags.value.length)
            }
            if (validtationMode.value === 'tagsAndComment' && tagsIsActive.value) {
                fieldsValid = Boolean(comment.value && selectedCommunicationTags.value.length)
            }
            if (validtationMode.value === 'tagsAndComment' && !tagsIsActive.value) {
                fieldsValid = Boolean(comment.value)
            }
            if (validtationMode.value === 'disabled') {
                fieldsValid = true
            }

            if (gcb2Store.actionModal.actionType === TASKLIST_ACTION_TYPES.CHANGE_EXPECTED_DATE) {
                return fieldsValid && postponedDate.value
            }
            return fieldsValid
        })

        const modalOpened = computed({
            get() {
                return gcb2Store.actionModal.opened
            },
            set(v: boolean) {
                if (!v) closeModal()
            },
        })
        watch(modalOpened, v => {
            setInitalData()
        })
        return {
            getAllowedPostponeDate,
            modalOpened,
            closeModal,
            manualCommunication,
            comment,
            postponedDate,
            makeAction,
            actionType,
            TASKLIST_ACTION_TYPES,
            communicationTags,
            selectedCommunicationTags,
            applyButtonText,
            isValid,
            tagsIsActive,
        }
    },
})
