import { render, staticRenderFns } from "./ClientBanDialog.vue?vue&type=template&id=92b5b38e&scoped=true"
import script from "./ClientBanDialog.vue?vue&type=script&lang=ts"
export * from "./ClientBanDialog.vue?vue&type=script&lang=ts"
import style0 from "./ClientBanDialog.vue?vue&type=style&index=0&id=92b5b38e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92b5b38e",
  null
  
)

export default component.exports