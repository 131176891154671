
import Vue, { PropType } from 'vue'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'

export default Vue.extend({
    components: { BaseInputOutlined },
    props: {
        value: { type: Array as PropType<number[]> },
        maxClientVisits: { type: Number },
        minClientVisits: { type: Number },
    },
    data: () => ({
        inputSlot: null,
        inputSlotClickEvent: null,
        menuOpened: false as boolean,
    }),
    computed: {
        label(): string {
            return `КОЛ-ВО ПОСЕЩЕНИЙ(${this.value[0] + '-' + this.value[1]})`
        },
    },
    watch: {
        maxClientVisits() {
            this.update([0, this.maxClientVisits])
        },
    },
    methods: {
        update(newValue: number[]) {
            this.$emit('input', newValue)
        },
    },
})
