
import Vue, { PropType } from 'vue'
import { Project } from '@root/src/types/main'
import Select from '@root/src/components/inputs/Select.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import _ from 'lodash'
import * as t from '@/store/chisai/GCB2/actionTypes'
import { TASKLIST_ACTION_TYPES } from '@root/src/vars/GCB2'
import tasklistActions from '@root/src/components/chisai/GCB2/taskList/actions'
import { communicationUpdate } from '@root/src/api/chisai/GCB2'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
export default Vue.extend({
    components: { Select, SpinnerLoader },
    props: {
        value: { type: Boolean as PropType<boolean> },
    },
    data: () => ({
        isConfirmed: false,
        serviceList: [] as any[],
        dataLoading: true as boolean,
        actionLoading: false as boolean,
        startSelected: [] as any[],
        selected: [] as any[],
    }),
    computed: {
        gcb2Store(): any  {
            return useGcb2Store()
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        diff(): { added: any[]; deleted: any[] } {
            return {
                added: _.difference(this.selected, this.startSelected),
                deleted: _.difference(this.startSelected, this.selected),
            }
        },
        selectedChanged(): boolean {
            return Boolean(this.diff.added.length || this.diff.deleted.length)
        },
        isDialog: {
            get(): boolean {
                return this.value
            },
            set(value: boolean): void {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        async isDialog(val) {
            if (this.isDialog) {
                this.setInitialData()
            }
            if (!val && !this.isConfirmed) {
                this.gcb2Store.closeSidebar()
            }
        },
    },
    methods: {
        async setInitialData() {
            this.dataLoading = true
            this.startSelected = [...this.selected]
            this.dataLoading = false
        },
        async changeBanServiceList() {
            this.actionLoading = true

            await tasklistActions.dontRemindService(this, this.project.id, this.diff)
            this.actionLoading = false
            this.isDialog = false
        },
        async startUpdate() {
            this.$store.commit('setCommunicationUpdate', true)
            this.isConfirmed = true
            this.isDialog = false
            await communicationUpdate({ projectId: this.project.id })
            this.isConfirmed = false
        }
    },
})
