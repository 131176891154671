import { batchModeSelectedCommunication, IGCB2Connector, ManualCommunication } from '@/types/GCB2'
import { sendMassMail } from '../../api/mailing'
import { Connector, Project } from '../../types/main'
import { CONNECTOR_TYPES } from '../../vars/general'

const messageVarsService = {
    getName,
    resolveVars,
}

function getName(gcb2Connector: IGCB2Connector, manualCommunication: ManualCommunication) {
    const clientExtra = manualCommunication.clientExtra ?? {}
    const name = clientExtra.user_defined_name ?? clientExtra.name ?? gcb2Connector.defaultName
    return name
}
function resolveVars(text: string, gcb2Connector: IGCB2Connector, manualCommunication: ManualCommunication) {
    const str = text || ''
    return str.replaceAll('{name}', getName(gcb2Connector, manualCommunication))
}

export default messageVarsService
