
import Vue, { PropType } from 'vue'
import { Project } from '../../../types/main'
import fb from '@/plugins/firebase'
import { setSnackBarNotification } from '../../../api/notification'

export default Vue.extend({
    components: {},
    props: {},
    data: () => ({
        notificationRef: null as any,
        notification: null as any,
        projectLastUpdate: 0,
        snackbarActive: false as boolean,
        defaultText: 'Загружены новые данные. Обновите проект, чтобы увидеть изменения.',
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        text(): string {
            return this.notification?.text ?? this.defaultText
        },
    },
    watch: {
        notification() {
            this.snackbarActive = this.notification && this.notification.active
        },
    },
    methods: {
        async closeNotification() {
            this.snackbarActive = false
            await setSnackBarNotification({
                projectId: this.project.id,
                active: false,
                type: 'GCB2',
                name: 'newData',
            })
        },
    },
    created() {
        this.notificationRef = fb.database().ref(`/project/${this.project.id}/notifcations/GCB2/newData`)
        this.notificationRef.on('value', (data: any) => {
            this.notification = data.val()
        })
    },
    destroyed() {
        this.notificationRef.off()
    },
})
