
import { computed, defineComponent, ref, PropType, watch } from 'vue'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import * as _ from 'lodash'
import { CommunicationTag } from '../../../../types/GCB2'
import CustomActivatorSelect from '../../../inputs/CustomActivatorSelect.vue'
export default defineComponent({
    components: { CustomActivatorSelect, BaseInputOutlined },
    props: {
        tagsList: {
            type: Array as PropType<CommunicationTag[]>,
            required: true,
        },
        value: {
            type: Array as PropType<string[]>,
            required: true,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const onInput = (value: string[]) => {
            emit('input', value)
        }
        return { onInput }
    },
})
