
import Vue, { PropType } from 'vue'
import { changeBanServiceList, getBanServiceList, taskListAction } from '../../../../api/chisai/GCB2'
import { Project } from '../../../../types/main'
import Select from '../../../inputs/Select.vue'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import _ from 'lodash'
import * as t from '@/store/chisai/GCB2/actionTypes'
import { TASKLIST_ACTION_TYPES } from '../../../../vars/GCB2'
import tasklistActions from '@root/src/components/chisai/GCB2/taskList/actions'
export default Vue.extend({
    components: { Select, SpinnerLoader },
    props: {
        value: { type: Boolean as PropType<boolean> },
    },
    data: () => ({
        serviceList: [] as any[],
        dataLoading: true as boolean,
        actionLoading: false as boolean,
        startSelected: [] as any[],
        selected: [] as any[],
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        diff(): { added: any[]; deleted: any[] } {
            return {
                added: _.difference(this.selected, this.startSelected),
                deleted: _.difference(this.startSelected, this.selected),
            }
        },
        selectedChanged(): boolean {
            return Boolean(this.diff.added.length || this.diff.deleted.length)
        },
        isDialog: {
            get(): boolean {
                return this.value
            },
            set(value: boolean): void {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        async isDialog() {
            if (this.isDialog) {
                this.setInitialData()
            }
        },
    },
    methods: {
        async setInitialData() {
            this.dataLoading = true
            this.serviceList = (await getBanServiceList({ projectId: this.project.id })).data || []
            this.selected = this.serviceList.filter(el => el.banned)
            this.startSelected = [...this.selected]
            this.dataLoading = false
        },
        async changeBanServiceList() {
            this.actionLoading = true

            await tasklistActions.dontRemindService(this, this.project.id, this.diff)
            this.actionLoading = false
            this.isDialog = false
        },
    },
})
