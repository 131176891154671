
import { computed, defineComponent, ref, watch } from 'vue'
import { useTaskListBatchModeStore } from '../../../../store/stores/gcb2/taskListBatchMode'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import { useProject } from '../../../../hooks/useProject'
const DEFAULT_LIMIT_TO_ADD = 10

export default defineComponent({
    components: { BaseInputOutlined },
    setup() {
        const { project } = useProject()
        const batchModeStore = useTaskListBatchModeStore()
        const limitToAdd = ref(DEFAULT_LIMIT_TO_ADD)

        const loading = ref(false)
        const applyButtonDisabled = computed(() => !limitToAdd.value)

        const isActive = computed({
            get: () => batchModeStore.addDailyLimitModalOpened,
            set: v => batchModeStore.setAddDailyLimitModalOpened(v),
        })
        const limitsExceeded = computed(
            () => batchModeStore.messageLimitIncludeSelected(project.value.id) === 0
        )

        const modalTitle = computed(() =>
            limitsExceeded.value ? 'ПРЕВЫШЕН ЛИМИТ НА ОТПРАВКУ!' : 'ИЗМЕНИТЬ ЛИМИТ'
        )

        const closeModal = () => batchModeStore.setAddDailyLimitModalOpened(false)
        const applyClick = async () => {
            loading.value = true
            await batchModeStore.addDailyMessageLimit(project.value.id, limitToAdd.value)
            batchModeStore.setAddDailyLimitModalOpened(false)
            loading.value = false
        }

        watch(isActive, () => {
            if (isActive) {
                limitToAdd.value = DEFAULT_LIMIT_TO_ADD
                loading.value = false
            }
        })

        return {
            batchModeStore,
            isActive,
            applyClick,
            closeModal,
            limitToAdd,
            loading,
            applyButtonDisabled,
            limitsExceeded,
            modalTitle,
        }
    },
})
