
import { computed, defineComponent, getCurrentInstance, ref } from 'vue'
import _ from 'lodash'
import { getQuiz, quizSaveAnswers } from '../../api/quiz'
import QuizQuestion from './quizQuestion.vue'
import { QuizNextQuestionParams } from '@/types/Quiz'
export default defineComponent({
    components: { QuizQuestion },
    props: {
        quizId: { type: String, required: true },
        delay: { type: Number, default: 10000 },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const quiz = ref({ show: false, quiz: null as any })
        const answers = ref({})
        const preference = ref()
        const quizClosed = ref<boolean>(false)
        const currentQuestionIndex = ref(0)
        const questions = computed(() => quiz.value?.quiz?.schema?.questions)
        const currentQuestion = computed(() => questions.value?.[currentQuestionIndex.value])
        const setQuestion = (e: QuizNextQuestionParams) => {
            if (e.type === 'inc') {
                currentQuestionIndex.value++
                return
            }
            if (e.type === 'dec') {
                currentQuestionIndex.value--
                return
            }
            if (e.type === 'close') {
                quizClosed.value = true
                return
            }
            if (e.id) {
                currentQuestionIndex.value = questions.value.findIndex((el: any) => el.id === e.id) ?? null
                return
            }
        }
        const setAnswer = ({ questionId, answer }: { questionId: string; answer: any }) => {
            answers.value = Object.assign({}, answers.value, { [questionId]: answer })
        }
        const setPreference = (e: any) => {
            preference.value = e
        }
        const saveAnswers = async () => {
            const { data, error } = await quizSaveAnswers({
                quizId: props.quizId,
                answers: answers.value,
                preference: preference.value,
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка')
            }
        }
        const onCreated = async () => {
            setTimeout(async () => {
                quiz.value = await getQuiz(props.quizId).then(res => res.data!)
            }, props.delay)
        }
        onCreated()
        return {
            quiz,
            currentQuestion,
            currentQuestionIndex,
            setQuestion,
            quizClosed,
            setAnswer,
            answers,
            setPreference,
            saveAnswers,
        }
    },
})
