import _ from 'lodash'

export const localStorageCache = {
    getAllCache: function() {
        const cache = JSON.parse(localStorage.getItem('taskListCache') || '{}')
        return cache
    },
    getProjectCache: function(projectId: string, cacheName: string) {
        const cache = JSON.parse(localStorage.getItem('taskListCache') || '{}')
        const projectCache = cache[projectId] ?? {}
        return projectCache
    },

    setProjectCache: function(projectId: string, cacheName: string, path: string, value: any) {
        const cache = this.getAllCache()
        localStorage.setItem('taskListCache', JSON.stringify(_.set(cache, `${projectId}.${path}`, value)))
    },
}
