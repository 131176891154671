
import { defineComponent, ref, PropType, computed, watch } from 'vue'
import _ from 'lodash'
import { QuizNextQuestionParams } from '@/types/Quiz'
import moment from 'moment'
export default defineComponent({
    props: {
        question: { type: null, required: true },
    },
    setup(props, { emit }) {
        const answer = ref<any>()
        const preference = ref({ frequency: 2, postponeTo: null as string | null })
        const questionType = computed(() => props.question?.type)
        const prefernceOptions = [
            { id: 2, label: 'Да, отвечу с удовольствием' },
            { id: 1, label: 'Да, но не очень часто' },
            { id: 0, label: 'Нет, я не хочу видеть такие вопросы' },
        ]
        const setAnswer = () => {
            let proccessedAnswer = answer.value
            if (props.question.type === 'radio') {
                proccessedAnswer = props.question.options.find((el: any) => el.id === answer.value)
            }
            emit('set-answer', { questionId: props.question.id, answer: proccessedAnswer })
        }
        const postponeQuiz = () => {
            preference.value.postponeTo = moment()
                .add('days', 5)
                .format('YYYY-MM-DD')

            nextQuestion({ id: '_PREFERENCE' })
        }
        const saveAnswers = () => {
            emit('set-preference', preference.value)
            emit('save-answers')
            nextQuestion({ type: 'inc' })
        }
        const nextQuestion = (e: QuizNextQuestionParams) => emit('next-question', e)

        const answerQuestion = (e: QuizNextQuestionParams) => {
            setAnswer()
            nextQuestion(e)
        }

        watch(
            () => props.question,
            () => {
                answer.value = undefined
                if (props.question.options) {
                    answer.value = props.question.options[0].id
                }
            }
        )

        const onCreated = async () => {}
        onCreated()
        return {
            questionType,
            nextQuestion,
            answer,
            answerQuestion,
            prefernceOptions,
            preference,
            saveAnswers,
            postponeQuiz,
        }
    },
})
