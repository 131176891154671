<template>
    <div>
        <div>
            <v-row class="ma-0">
                <v-col>
                    <div
                        class="value-wrapper"
                        :class="communicationCount >= 0 ? 'plus-border ' : 'minus-border'"
                    >
                        <div class="value-content">
                            <div class="value-name-wrapper"><span class="value-name">Коммуникаций</span></div>

                            <div>
                                <span class="result-value">{{ communicationCount }}</span>
                            </div>
                            <span :class="communicationCountDiff >= 0 ? 'diff-plus ' : 'diff-minus'">
                                {{ communicationCountDiff }}
                            </span>
                        </div>
                    </div>
                </v-col>

                <v-col>
                    <div
                        class="value-wrapper"
                        :class="recordsAfterCommunicationCount >= 0 ? 'plus-border ' : 'minus-border'"
                    >
                        <div class="value-content">
                            <div class="value-name-wrapper">
                                <span class="value-name">Записей после коммуникаций</span>
                            </div>

                            <div>
                                <span class="result-value">{{ recordsAfterCommunicationCount }}</span>
                            </div>
                            <span
                                :class="recordsAfterCommunicationCountDiff >= 0 ? 'diff-plus ' : 'diff-minus'"
                            >
                                {{ recordsAfterCommunicationCountDiff }}
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col><v-spacer></v-spacer> </v-col>
                <v-col><v-spacer></v-spacer> </v-col>
            </v-row>
        </div>
    </div>
</template>


<script>
import { sumBy } from 'lodash'
import * as t from '@/store/chisai/GCB2/actionTypes'
export default {
    name: 'GCBAdminReportResultValues',
    props: {
        prevPeriodData: {
            type: Array,
            default: () => [],
        },
        currentPeriodData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({}),
    computed: {
        communicationCount() {
            return this.currentPeriodData.length
        },
        prevPeriodCommuncationCount() {
            return this.prevPeriodData.length
        },
        communicationCountDiff() {
            return this.communicationCount - this.prevPeriodCommuncationCount
        },
        recordsAfterCommunicationCount() {
            return sumBy(this.currentPeriodData, 'nextRecordStatus')
        },
        prevPeriodRecordsAfterCommunicationCount() {
            return sumBy(this.prevPeriodData, 'nextRecordStatus')
        },
        recordsAfterCommunicationCountDiff() {
            return this.recordsAfterCommunicationCount - this.prevPeriodRecordsAfterCommunicationCount
        },
    },
    methods: {},
    created() {},
}
</script>
<style lang="sass" scoped>
.result-value
    font-size: 42px !important
.value-content
    padding: 16px
    height: 138px !important
.value-name
    font-size: 14px
    height: 30px !important

.value-wrapper
    width: 240px
    background-color: #2A3B510B
    display: flex
    flex-direction: column
.plus-border
    border-bottom: 2px solid #00B74F
.minus-border
    border-bottom: 2px solid #E20029
.diff-plus
    color: #00B74F
.diff-minus
    color: #E20029
.icon
    color: inherit
</style>
