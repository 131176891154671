
import * as _ from 'lodash'
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, PropType, ref, watch } from 'vue'
import { Connector } from '@/types/main'
import NameBadge from './NameBadge.vue'
import { MESSAGE_VARS } from '../../../vars/GCB2'
import messageVarsService from '../../../helpers/gcb2/messageVars'
import { ManualCommunication } from '../../../types/GCB2'
import messages from '@dataheroes/messages'
import { useProject } from '../../../hooks/useProject'
import { useUser } from '../../../hooks/useUser'
type EditorState = 'view' | 'edit'

const varsIds = messages.vars.vars.map(el => el.aliases.map(alias => `{${alias}}`)).flat()
export default defineComponent({
    components: { NameBadge },
    props: {
        label: { type: String },
        value: { type: String, required: true },
        readonly: { type: Boolean, default: false },
        placeholder: { type: String, default: 'Введите текст...' },
        fontSize: { type: Number, default: 12 },
        gcb2Connector: { type: Object as PropType<Connector>, default: undefined },
        manualCommunication: { type: Object as PropType<ManualCommunication>, default: undefined },
    },
    setup(props, { emit, }) {
        const root = getCurrentInstance()!.proxy
        const { project } = useProject()
        const { user } = useUser()
        const editorState = ref<EditorState>('view')
        const tooltip = ref(false)
        const editorViewerHeight = ref(0)
        const autoGrow = ref(false)
        const varsRegexp = computed(() => new RegExp(varsIds.join('|'), 'g'))
        const processedValue = computed((): any[] => {
            let result: any[] = []
            const maybeVars: any[] = []
            const splitted: string[] = []
            let curStrIndex = 0

            const matches = props.value ? props.value.matchAll(varsRegexp.value) : []
            const matchesArr = [...matches]

            if (matchesArr.length === 0) {
                result = [{ id: 1, val: props.value }]
                return result
            }
            for (let match of matchesArr) {
                maybeVars.push({
                    val: match[0],
                    startIndex: match.index,
                    endIndex: Number(match.index) + match[0].length,
                })
            }
            maybeVars.forEach((el, i) => {
                splitted.push(props.value.substring(curStrIndex, el.startIndex))
                splitted.push(el.val)
                if (i === maybeVars.length - 1) {
                    splitted.push(props.value.substring(el.endIndex, props.value.length))
                }
                curStrIndex = el.endIndex
            })
            result = splitted.map((el, i) =>
                varsIds.includes(el) ? { id: i, varId: el } : { id: i, val: el }
            )
            return result
        })
        const copy = () => {
            navigator.clipboard.writeText(
                messageVarsService.resolveVars(props.value, props.gcb2Connector, props.manualCommunication)
            )
            setTimeout(() => {
                tooltip.value = false
            }, 1000)
        }
        const setEditorState = (state: EditorState) => {
            if (props.readonly) return
            if (state === 'edit') {
                setTimeout(() => {
                    ;(root.$refs.textarea as HTMLInputElement).focus()
                    autoGrow.value = true
                }, 0)
            } else {
                autoGrow.value = false
            }
            editorState.value = state
        }
        watch(
            () => props.value,
            () => {
                if (editorState.value === 'view') {
                    setTimeout(() => {
                        const editorContentViewer: HTMLElement = root.$refs.editorContentViewer as HTMLElement
                        if (editorContentViewer) {
                            editorViewerHeight.value = parseFloat(String(editorContentViewer.offsetHeight))
                            ;(root.$refs.textarea as any).$refs.input.style.height = editorViewerHeight.value + 'px'
                        }
                    }, 0)
                }
            },
            { immediate: true }
        )
        const getVarValue = (varId: string) => {
            const text = messages.substituteVars({
                text: varId,
                data: {
                    communication: props.manualCommunication,
                    gcb2Connector: props.gcb2Connector,
                    project: project.value,
                    userId: user.value.sub,
                },
            }).text
            console.log({comm: props.manualCommunication, gcb2: props.gcb2Connector, project:  project.value, user: user.value.sub})
            return text
        }
        return {
            props,
            emit,
            copy,
            autoGrow,
            tooltip,
            editorState,
            setEditorState,
            processedValue,
            editorViewerHeight,
            messages,
            getVarValue,
        }
    },
})
