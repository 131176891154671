<template>
    <div :style="`max-width: ${width}px`">
        <v-select
            v-on="$listeners"
            :items="itemsToDisplay"
            item-text="name"
            item-value="id"
            :multiple="true"
            :return-object="true"
            :placeholder="placeholder"
            :hide-details="hideDetails"
            :filled="filled"
            :color="color"
            :hint="hint"
            :persistent-hint="persistentHint"
            :label="label"
            :rules="rules"
            :value="value"
            @input="update"
        >
            <template slot="prepend-item">
                <v-list-item @click.stop="setSelectionStateAndMode()">
                    <v-list-item-action>
                        <v-icon :color="state !== 'NONE' ? color : ''">{{ icon }}</v-icon>
                    </v-list-item-action>

                    <v-list-item-title>{{ label }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <span>{{ selectedText }}</span>
                        <span class="grey--text caption">{{ selectedTextCount }}</span>
                    </v-list-item-title>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
                <v-list-item class="search-tile">
                    <v-text-field
                        v-model="q"
                        pend-inner-icon="mdi-magnify"
                        placeholder="Введите запрос"
                        class="pt-0"
                        :color="color"
                        :hint="searchHint"
                        @input="search($event)"
                    ></v-text-field>
                </v-list-item>
            </template>

            <template slot="selection" slot-scope="data">
                <span v-if="data.index === 0">
                    <span>{{ selectedText }}</span>
                    <span class="grey--text caption">{{ selectedTextCount }}</span>
                </span>
            </template>

            <template slot="item" slot-scope="data">
                <v-hover v-slot="{ hover }">
                    <v-list-item style="width: 200px" class="content">
                        <v-list-item-action>
                            <v-checkbox
                                :color="color"
                                :input-value="getCheckboxValue(data)"
                                readonly
                            ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content style="width: 200px">
                            <v-list-item-title>
                                <v-tooltip left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on"> {{ data.item.name }}</span>
                                    </template>
                                    <span class="white--text">{{ data.item.name }}</span>
                                </v-tooltip>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="hidden" v-if="hover">
                            <v-btn @click.stop="selectOnlyOne(data.item)" small class="only-btn ma-0" outlined
                                >ТОЛЬКО</v-btn
                            >
                        </v-list-item-action>
                    </v-list-item>
                </v-hover>
            </template>
            <template slot="no-data">
                <span></span>
            </template>
        </v-select>
    </div>
</template>

<script>
import { debounce, throttle } from 'lodash'
const DEBOUNCE_TIEMOUT = 300
// @ is an alias to /src
export default {
    name: 'DataFilter',
    data: () => ({
        state: 'ALL', //'ALL', 'NONE', 'SOME',
        mode: 'EXCLUDE', // 'INCLUDE', 'EXCLUDE'
        displayItems: true,
        q: '',
        interactedItems: [], //
    }),
    //hint - короткая подсказка
    //tooltipText - расширенный техт подсказки
    props: {
        value: {
            type: null,
            default: undefined,
        },
        newStateAndMode: {
            type: Object,
            default: undefined,
        },
        width: {
            type: Number,
            default: 250,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: 'main',
        },
        hint: {
            type: String,
            default: undefined,
        },
        persistentHint: {
            type: Boolean,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        rules: {
            type: null, //any
            default: undefined,
        },
        items: {
            type: Array,
            default: [],
        },
        tooltipText: {
            type: String,
            default: undefined,
        },
        hideDetails: {
            type: Boolean,
            default: undefined,
        },
    },
    components: {},
    computed: {
        selectedAll() {
            return this.value.length === this.items.length
        },
        searchHint() {
            if (this.q.length < 3) {
                return 'Минимум три символа'
            } else {
                return null
            }
        },
        icon() {
            if (this.state == 'ALL') return 'mdi-checkbox-outline'
            if (this.state == 'SOME') return 'mdi-minus-box-outline'
            return 'mdi-checkbox-blank-outline'
        },
        itemsToDisplay() {
            if (!this.q || !this.displayItems) {
                return []
            } else {
                return this.items
            }
        },
        interactedItemsIds() {
            return this.interactedItems.map((el) => el.id)
        },
        selectedText() {
            if (this.state === 'ALL') {
                return 'Выбрано все'
            } else if (this.state === 'NONE') {
                return 'Не выбрано ничего'
            } else if (this.mode === 'EXCLUDE') {
                if (this.value[0].name.length > 8) {
                    return `Все кроме ${this.value[0].name.slice(0, 4)}...`
                } else {
                    return `Все кроме ${this.value[0].name}`
                }
            } else if (this.mode === 'INCLUDE') {
                if (this.value[0].name.length > 8) {
                    return `Только ${this.value[0].name.slice(0, 8)}...`
                } else {
                    return `Только ${this.value[0].name}`
                }
            } else {
                return 'ОШИБКА'
            }
        },
        selectedTextCount() {
            if (this.state === 'SOME' && this.value.length !== 1) {
                return `(+${this.value.length - 1})`
            } else {
                return ''
            }
        },
        placeholder() {
            if (this.state !== 'SOME') {
                return this.selectedText
            } else {
                return null
            }
        },
    },
    watch: {
        state: {
            immediate: true,
            handler(value) {
                this.$emit('stateChanged', value)
            },
        },
        mode: {
            immediate: true,
            handler(value) {
                this.$emit('modeChanged', value)
            },
        },
        newStateAndMode: {
            //immediate: true,
            handler(value) {
                this.state = value.state
                this.mode = value.mode
            },
        },
        value() {
            if (this.value.length !== 0) {
                this.state = 'SOME'
            } else {
                if (this.mode === 'EXCLUDE') {
                    this.state = 'ALL'
                } else {
                    this.state = 'NONE'
                }
            }
        },
    },
    methods: {
        search: debounce(function (e) {
            if (e.length >= 3) {
                this.displayItems = true
                this.$emit('searchInput', e)
            } else {
                this.displayItems = false
            }
        }, DEBOUNCE_TIEMOUT),
        update(newValue) {
            this.$emit('input', newValue)
        },
        getCheckboxValue(data) {
            console.log('checkbox changed')
            if (this.state === 'ALL') return true
            if (this.state === 'NONE') return false
            if (this.mode === 'EXCLUDE') {
                return !data.parent.selectedItems.map((el) => el.id).includes(data.item.id)
            } else {
                return data.parent.selectedItems.map((el) => el.id).includes(data.item.id)
            }
        },
        setSelectionStateAndMode() {
            if (this.state === 'ALL' && this.value.length === 0) {
                this.state = 'NONE'
                this.mode = 'INCLUDE'
            } else if (this.state === 'NONE' && this.value.length === 0) {
                this.state = 'ALL'
                this.mode = 'EXCLUDE'
            } else if (this.state === 'SOME') {
                this.state = 'ALL'
                this.mode = 'EXCLUDE'
            } else {
                this.state = 'SOME'
            }
            if (this.state != 'SOME') {
                this.update([])
            }

            // update(selectedAll ? [] : items.slice())
        },
        selectOnlyOne(item) {
            this.state = 'SOME'
            this.mode = 'INCLUDE'
            this.update([item])
        },
    },
    created() {},
    mounted() {},
}
</script>
<style lang='sass' scoped>
@import '@/vars.sass'
.hint-icon
    color: $hint-icon-color
    opacity: 0.38
.hint-icon:hover
    color: black
    cursor: pointer
    opacity: 1

.v-list-item-content
    padding: 0 0 0 0px
.v-list-item__action
    margin-right: 12px !important
.hidden
    margin: 0 0 0 0 !important
.content
    padding: 0 0 0 0 !important
</style>