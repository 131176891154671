
import { computed, defineComponent, ref, PropType, watch, getCurrentInstance } from 'vue'
import { IGCB2Connector, ManualCommunication } from '../../../types/GCB2'
import messages from '@dataheroes/messages'
import messageVarsService from '../../../helpers/gcb2/messageVars'
import { v4 as uuidv4 } from 'uuid'
import BaseInputOutlined from '../BaseInputOutlined.vue'
import { updateMessageVarExeption } from '../../../api/chisai/GCB2'
export default defineComponent({
    components: { BaseInputOutlined },
    props: {
        gcb2Connector: { type: Object as PropType<IGCB2Connector>, default: () => {} },
        manualCommunication: { type: Object as PropType<ManualCommunication>, default: () => {} },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const id = ref(`message-need-to-confirm-${uuidv4()}`)

        const name = computed(() => {
            return messages.vars.substituteVars({
                text: '{name}',
                data: { gcb2Connector: props.gcb2Connector, communication: props.manualCommunication },
            }).text
        })

        const loading = ref(false)
        const isModalOpened = ref(false)
        const clientExtra = ref()
        const clientName = ref()
        const confirmed = ref()
        const needToConfirm = computed(() => {
            if (clientExtra.value.confirmed === false) {
                return true
            } else if (clientExtra.value.user_defined_name) {
                return false
            } else {
                return clientExtra.value.need_to_confirm
            }
        })
        watch(
            () => props.manualCommunication,
            () => {
                clientExtra.value = props.manualCommunication.clientExtra
                    ? Object.assign({}, props.manualCommunication.clientExtra)
                    : {}
                clientName.value = clientExtra.value.user_defined_name ?? clientExtra.value.name

                confirmed.value = !needToConfirm.value
            },
            { immediate: true, deep: true }
        )

        const needToConfirmTooltipText = ref('Рекомендуем проверить имя')

        const changeExeption = async () => {
            loading.value = true
            await updateMessageVarExeption({
                projectId: props.gcb2Connector.projectId,
                clientId: props.manualCommunication.clientId,
                value: clientName.value,
                varId: '{name}',
                confirmed: confirmed.value,
            })
            root.$store.commit(`GCB2/TASKLIST_CHANGE_ITEM`, {
                communicationId: props.manualCommunication.communicationId,
                key: 'clientExtra',
                value: Object.assign({}, props.manualCommunication.clientExtra, {
                    user_defined_name: clientName.value,
                    confirmed: confirmed.value,
                }),
            })
            loading.value = false
            isModalOpened.value = false
        }

        return {
            id,
            changeExeption,
            needToConfirm,
            name,
            needToConfirmTooltipText,
            clientName,
            confirmed,
            isModalOpened,
            loading,
        }
    },
})
