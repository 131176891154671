
import Vue, { PropType } from 'vue'
import { Connector, Project, Tag } from '@/types/main'

import {
    deleteActivation,
    getOverviewCardStat,
    setChangedActivationNotification,
    swapActivationOrder,
    swapActivationPriority,
    toogleIsRunning,
} from '@/api/chisai/GCB2'
SpinnerLoader
import _, { findIndex } from 'lodash'
import TriggerCard from '@/components/chisai/GCB2/bot/TriggerCard/TriggerCard.vue'
import { CONNECTOR_TYPES } from '@/vars/general'
import { checkRole } from '@/helpers/roles'
import { mod } from '@/utils'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import api from '@/api/api'
import { BOT_TRIGGER_CARDS } from '../../../vars/GCB2'
import { canActivateTrigger } from '../../../helpers/whatsapp'
import DemoSubView from './DemoSubView.vue'

export default Vue.extend({
    components: { SpinnerLoader, TriggerCard, DemoSubView },

    data: () => ({
        triggers: {} as any,
        loading: true,
        cardsUpdatedTimestamp: 0,
        selectedTags: [],
    }),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        botConnector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.whatsappBasis)
        },
        activeTriggersIds(): string[] {
            return Object.keys(this.triggers)
                .map(id => ({ id, ...this.triggers[id as any] }))
                .filter(trigger => trigger.status === 'on')
                .map(trigger => trigger.id)
        },
        cards(): any[] {
            return Object.keys(this.triggers)!.map((key: any) => ({
                id: key,
                title: _.get(BOT_TRIGGER_CARDS, `${key}.title`),
                description: _.get(BOT_TRIGGER_CARDS, `${key}.description`),
                isRunning: this.triggers[key].status === 'on' ? true : false,
            }))
        },
        activeCards(): any[] {
            return this.cards.filter(el => el.isRunning)
        },
        inactiveCards(): any[] {
            return this.cards.filter(el => !el.isRunning)
        },
    },

    methods: {
        checkRole,
        async changeIsRunning({ id, value }: { id: string; value: boolean }) {
            if (!canActivateTrigger(id, this.activeTriggersIds).canActivate) {
                return
            }
            const updatedTrigger = {
                [id]: Object.assign({}, this.triggers[id as any], { status: value ? 'on' : 'off' }),
            }
            this.triggers = Object.assign({}, this.triggers, updatedTrigger)
            const { data, error } = await api.networkFixer.updateApp({
                connectorId: this.botConnector?.connectorId!,
                data: {
                    triggers: updatedTrigger,
                },
            })
            if (error) {
                this.$store.dispatch('callNotify', 'Произошла ошибка')
                this.$store.commit('endLoading')
                return
            }
        },

        async setTriggers() {
            this.loading = true
            this.triggers = await api.networkFixer
                .getApp({ connectorId: this.botConnector!.connectorId })
                .then(res => res.data!.data.triggers)

            this.loading = false
        },
    },

    mounted() {
        this.setTriggers()
    },
})
