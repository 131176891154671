<template>
    <div>
        <div class="sidebar-spacer" v-if="sidebarOpened"></div>
        <v-navigation-drawer
            class="GCB2-sidebar"
            :value="sidebarOpened"
            fixed
            right
            width="420px"
            :mobile-breakpoint="0"
        >
            <div class="sidebar-header-wrapper" v-if="activeSection !== SIDEBAR_SECTIONS.batchMode">
                <div class="sidebar-header pt-4">
                    <v-row class="ma-0" justify="space-between" align="center">
                        <div>
                            <v-tooltip
                                bottom
                                color="#000000DE"
                                max-width="300px"
                                v-if="needUpdateVisitHistory"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-if="needUpdateVisitHistory"
                                        class="d-flex align-center"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon class="mr-1" color="error" :size="16"
                                            >mdi-alert-circle</v-icon
                                        >
                                        <span class="need-update-visit-history-text"
                                            >Новая запись. Проверьте историю посещений.
                                        </span>
                                    </div>
                                </template>
                                <span class="white--text"
                                    >Это оповещение пропадет после обновления расчетов.</span
                                >
                            </v-tooltip>
                        </div>
                        <v-btn icon @click="closeSidebar"><v-icon> mdi-close </v-icon></v-btn>
                    </v-row>
                </div>
            </div>
            <div :class="{ 'sidebar-content': activeSection !== SIDEBAR_SECTIONS.batchMode }">
                <component :is="activeComponent"></component>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { SECTIONS, SIDEBAR_SECTIONS } from '@/vars/GCB2'
import * as t from '@root/src/store/chisai/GCB2/actionTypes'

import { localStorageCache } from '@root/src/helpers/localStorageCache'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { mapStores } from 'pinia'

const ClientInfo = () => import('@root/src/views/GCB2/sidebarViews/ClientInfo.vue')
const BatchMode = () => import('@root/src/views/GCB2/sidebarViews/BatchMode.vue')

export default {
    name: 'GCB2Sidebar',
    components: {},
    data: () => ({
        views: [
            { section: SIDEBAR_SECTIONS.clientInfo, component: ClientInfo },
            { section: SIDEBAR_SECTIONS.batchMode, component: BatchMode },
        ],
        SECTIONS,
        SIDEBAR_SECTIONS,
    }),
    computed: {
        ...mapStores(useGcb2Store),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        sidebarOpened() {
            return this.gcb2Store.sidebar.active
        },

        activeSection() {
            return this.gcb2Store.sidebar.section
        },
        activeComponent() {
            return this.views.find(view => view.section === this.activeSection)?.component
        },
        activeManualCommunication() {
            return this.$store.getters[`GCB2/${t.GET_ONE_MANUAL_COMMUNICATION}`](
                this.gcb2Store.sidebar.communicationId
            )
        },
        needUpdateVisitHistory() {
            if (!this.activeManualCommunication) return false
            return this.gcb2Store.needUpdateVisitHistory(
                this.project.id,
                this.activeManualCommunication.clientId
            )
        },
    },
    watch: {
        sidebarOpened() {
            if (!this.sidebarOpened) {
                this.gcb2Store.closeSidebar()
                this.removeMarginFromContent()
                this.removeMarginFromBreadcrumbs()
            } else {
                this.addMarginToContent()
                this.addMarginToBreadcrumbs()
            }
        },
    },
    methods: {
        closeSidebar() {
            this.gcb2Store.closeSidebar()
            localStorageCache.setProjectCache(this.project.id, 'taskListCache', 'selectedRowId', null)
        },
        addMarginToContent() {
            const contentWrapper = document.getElementsByClassName('content-wrapper')[0]
            const content = contentWrapper.getElementsByClassName('content')[0]
            content.classList.add('sidebar-is-open')
        },
        removeMarginFromContent() {
            const contentWrapper = document.getElementsByClassName('content-wrapper')[0]
            const content = contentWrapper.getElementsByClassName('content')[0]
            content.classList.remove('sidebar-is-open')
        },
        addMarginToBreadcrumbs() {
            const contentWrapper = document.getElementsByClassName('breadcrumbs-wrapper')[0]
            const content = contentWrapper.getElementsByClassName('breadcrumbs')[0]
            contentWrapper.classList.add('sidebar-is-open')
            content.classList.add('sidebar-is-open')
        },
        removeMarginFromBreadcrumbs() {
            const contentWrapper = document.getElementsByClassName('breadcrumbs-wrapper')[0]
            const content = contentWrapper.getElementsByClassName('breadcrumbs')[0]
            contentWrapper.classList.remove('sidebar-is-open')
            content.classList.remove('sidebar-is-open')
        },
    },
    beforeDestroy() {
        this.removeMarginFromContent()
        this.removeMarginFromBreadcrumbs()
        this.gcb2Store.closeSidebar()
    },
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
//фиксит размытие
::v-deep .v-navigation-drawer
    will-change: initial
::v-deep aside
    z-index: 101 !important
::v-deep .v-navigation-drawer__content::-webkit-scrollbar
    display: none

.hint-content
    margin-right: 132px
.sidebar-content
    margin-top: 100px
.sidebar-spacer
    max-width: 420px
    min-width: 420px
    height: 100vh
    flex-grow: 1
    overflow-y: auto
    overflow-x: hidden
    background-color: transparent
.sidebar-header-wrapper
    position: fixed
    margin-top: 56px
    z-index: 103
    display: flex
    width: 100%
    flex-direction: row
    justify-items: flex-end
    border-left: 1px solid $light-gray-2
.sidebar-header
    padding-left: 16px
    padding-right: 16px
    width: 100%
    height: 100%
    background-color: white
.need-update-visit-history-text
    color: $red
    font-size: 12px
</style>
