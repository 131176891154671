
import { computed, defineComponent } from 'vue'

export default defineComponent({
    props: {
        batchModeSelectedCommunicationsIds: { type: Array, required: true },
        item: { type: Object, required: true },
        batchModeSelectLimit: { type: Number, required: true },
    },
    setup(props) {
        const checkboxValue = computed(() =>
            props.batchModeSelectedCommunicationsIds.includes(props.item.communicationId)
        )
        const phone = computed(() => props.item.hrefInfo.find((e: any) => e.connectorType === 'tel')?.phone)

        const limitExceeded = computed(
            () =>
                !checkboxValue.value &&
                props.batchModeSelectedCommunicationsIds.length >= props.batchModeSelectLimit
        )
        const disabled = computed(() => limitExceeded.value || !phone.value)
        const tooltipText = computed(() => {
            if (!phone.value) return `Отсутствует номер телефона`
            if (limitExceeded.value)
                return `Превышен лимит отправленных сообщений (${props.batchModeSelectLimit}), кликните чтобы увеличить лимит`
        })

        return { checkboxValue, disabled, tooltipText }
    },
})
