
import Vue, { PropType } from 'vue'
import BaseInputOutlined from '../../../inputs/BaseInputOutlined.vue'
import TextField from '../../../inputs/TextField.vue'
import { v4 as uuidv4 } from 'uuid'
export default Vue.extend({
    components: { BaseInputOutlined, TextField },
    props: {
        value: { type: String as PropType<string> },
    },
    data: () => ({
        inputSlot: null,
        inputSlotClickEvent: null,
        menuOpened: false as boolean,
    }),
    computed: {
        id() {
            return 'phone-filter' + '-' + uuidv4()
        },
    },

    methods: {
        update(newValue: number[]) {
            this.$emit('input', newValue)
        },
        sumbmit() {
            this.menuOpened = false
            this.$emit('submit', this.value)
        },
    },
})
