
import { computed, defineComponent, PropType } from 'vue'
import { useTaskListBatchModeStore } from '@/store/stores/gcb2/taskListBatchMode'
import { storeToRefs } from 'pinia'
import _ from 'lodash'
import { ManualCommunication } from '../../../../types/GCB2'
type checkboxState = 'ALL' | 'SOME' | 'NONE' | 'DISABLED'
export default defineComponent({
    props: {
        batchModeSelectedCommunicationsIds: { type: Array, required: true },
        currentItems: { type: Array as PropType<ManualCommunication[]>, required: true },
        batchModeSelectLimit: { type: Number, required: true },
    },
    setup(props) {
        const taskListBatchModeStore = useTaskListBatchModeStore()
        const { selectedCommunicationsIds } = storeToRefs(taskListBatchModeStore)
        const currentItemsWithPhone = computed(() =>
            props.currentItems.filter(el => el.hrefInfo.find(el => el.connectorType === 'tel')?.phone)
        )
        const currentItemsCommunicationIds = computed(() =>
            _.map(currentItemsWithPhone.value, 'communicationId')
        )
        const state = computed(
            (): checkboxState => {
                const intersectionIds = _.intersection(
                    currentItemsCommunicationIds.value,
                    selectedCommunicationsIds.value
                )
                if (
                    intersectionIds.length === 0 &&
                    props.batchModeSelectLimit === selectedCommunicationsIds.value.length
                ) {
                    return 'DISABLED'
                } else if (intersectionIds.length === currentItemsCommunicationIds.value.length) {
                    return 'ALL'
                } else if (intersectionIds.length === 0) {
                    return 'NONE'
                } else {
                    return 'SOME'
                }
            }
        )
        const icon = computed(() => {
            if (state.value == 'ALL') return 'mdi-checkbox-marked'
            if (state.value == 'SOME') return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        })
        const chechboxClick = () => {
            if (state.value === 'NONE') {
                const availableItemsCount =
                    props.batchModeSelectLimit - selectedCommunicationsIds.value.length //Сколько еще строк можно выбрать, не нарушая лимиты
                taskListBatchModeStore.selectedCommunications = [
                    ...taskListBatchModeStore.selectedCommunications,
                    ...currentItemsWithPhone.value.slice(0, availableItemsCount),
                ]
            } else {
                taskListBatchModeStore.selectedCommunications = taskListBatchModeStore.selectedCommunications.filter(
                    el => !currentItemsCommunicationIds.value.includes(el.communicationId)
                )
            }
        }
        const tooltipText = computed(() => `Превышен лимит выбранных строк (${props.batchModeSelectLimit})`)
        return { state, icon, chechboxClick, tooltipText }
    },
})
