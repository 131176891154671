import { render, staticRenderFns } from "./NameBadge.vue?vue&type=template&id=9a317a06&scoped=true"
import script from "./NameBadge.vue?vue&type=script&lang=ts"
export * from "./NameBadge.vue?vue&type=script&lang=ts"
import style0 from "./NameBadge.vue?vue&type=style&index=0&id=9a317a06&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a317a06",
  null
  
)

export default component.exports