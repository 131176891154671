
import Vue, { PropType } from 'vue'
import { Project } from '@/types/main'
import { checkRole } from '../../../../helpers/roles'
import { query } from 'express'
export default Vue.extend({
    props: {
        activationCardData: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        toogleIsRunningText(): string {
            return this.activationCardData.isRunning ? 'Деактивировать' : 'Активировать'
        },
        hasEditRole(): boolean {
            return this.checkRole(this.project.id, 'gcb2:activation:edit')
        },
        hasDeleteRole(): boolean {
            return this.checkRole(this.project.id, 'gcb2:activation:delete')
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        disabled(): boolean {
            return this.activationCardData.disabled
        },
    },
    methods: {
        checkRole,
        copyActivation() {
            this.$emit('copy-activation', this.activationCardData.activationId)
        },
        goToSettings() {
            this.$router.push({
                path: `${this.$router.currentRoute.path}/activation`,
                query: {
                    mode: 'edit',
                    activationId: this.activationCardData.activationId,
                    tags: this.$router.currentRoute.query.tags,
                },
            })
        },
        deleteActivation() {
            this.$emit('delete-activation', this.activationCardData.activationId)
        },
        changeIsRunning() {
            this.$emit('change-is-running', {
                activationId: this.activationCardData.activationId,
                value: !this.activationCardData.isRunning,
            })
        },
    },
})
