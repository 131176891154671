
import { computed, defineComponent, getCurrentInstance, ref } from 'vue'
import { nmSendCallReport } from '../../../api/chisai/GCB2'
import { useConfirmStore } from '../../../store/stores/confirm'
export default defineComponent({
    components: {},
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const confirmStore = useConfirmStore()
        const user = computed(() => root.$auth.user)
        const projectId = computed(() => root.$route.params.id)
        const sendReport = async (reportType: string) => {
            confirmStore.confirm({
                applyText: 'Ок',
                text: `Все эти контакты будут сразу же помечены как проработанные, поэтому нажимайте только если планируете действительно обзвонить эти контакты. \n\nВыгрузка придет на вашу почту ${user.value.email} в течение часа. \n\nВы подтверждаете формирование выгрузки?.
                `,
                onConfirm: async () => {
                    const { data, error } = await nmSendCallReport({
                        projectId: projectId.value,
                        email: user.value.email,
                        reportType,
                    })
                    if (error) {
                        root.$store.dispatch('callNotify', 'Ошибка')
                    }
                },
            })
        }

        return { sendReport }
    },
})
