
import Vue, { PropType } from 'vue'
import { Project } from '@/types/main'

import _, { findIndex } from 'lodash'
import moment from 'moment'
import TriggerCardMenu from './TriggerCardMenu.vue'
import { canActivateTrigger } from '../../../../../helpers/whatsapp'
import { checkRole } from '../../../../../helpers/roles'

export default Vue.extend({
    components: { TriggerCardMenu },
    props: {
        cardData: {
            type: Object,
            default: () => {},
        },
        isRunningSwitchDisabled: {
            type: Boolean,
            default: false,
        },
        activeTriggersIds: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    data: () => ({}),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },
    methods: {
        canActivateTrigger,
        checkRole,
        changeIsRunning({ id, value }: { id: string; value: boolean }) {
            this.$emit('change-is-running', { id, value })
        },
    },

    mounted() {},
})
