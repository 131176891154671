
import Vue, { PropType } from 'vue'
import _ from 'lodash'
import readXlsxFile from 'read-excel-file'
import { changeBanClientList } from '../../../../api/chisai/GCB2'

export default Vue.extend({
    components: {},
    props: {
        value: { type: Boolean as PropType<boolean> },
    },
    data: () => ({
        loadedFileName: '',
        phones: [],
        loading : false
    }),
    computed: {
        isDialog: {
            get(): boolean {
                return this.value
            },
            set(value: boolean): void {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        async isDialog() {
            if (this.isDialog) {
            }
        },
    },
    methods: {
        async send() {
            this.loading = true
            changeBanClientList({
                projectId : this.$router.currentRoute.params.id,
                telephonesList : this.phones
            })
            .then(() => {
                this.$store.dispatch('callNotify', 'Файл успешно загружен. Необходимо обновить расчёты')
            })
            .catch(() => {
                this.$store.dispatch('callNotify', 'Ошибка при загрузке файла')
            })
            .finally(() => {
                this.loading = false
                this.isDialog = false
                this.loadedFileName = ""
            })
        },
        validatePhoneNumber(phone: any) {
            phone = phone[0]

            if (!phone) {
                return false
            }
            phone = phone.toString()
            // Убираем все дефисы и пробелы
            phone = phone.replace(/[-\s]/g, '')

            // Проверяем наличие букв и спецсимволов (кроме +)
            if (/[^0-9+]/.test(phone)) {
                return ''
            }

            // Если номер не начинается с +, добавляем его
            if (phone[0] !== '+') {
                phone = '+' + phone
            }

            phone = '+7' + phone.slice(2)
            // Проверяем, что номер начинается с +7 и имеет длину 12 (включая +)
            if (phone.length === 12) {
                return phone
            } else {
                return ''
            }
        },

        loadFile(e) {
            try {
                const file: File = e.target.files[0]
                this.loadedFileName = file.name
                readXlsxFile(file).then((res) => {
                    let data = res.map(this.validatePhoneNumber).filter((item) => item)
                    this.phones = data
                })
            } catch (e) {
                this.loadedFileName = ""
                console.log("error")
            }
            
        },
    },
})
