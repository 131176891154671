<template>
    <div>
        <div class="d-flex justify-center align-center" v-if="loading">
            <div>
                <div style="width: 100%; display: flex; justify-content: center">
                    <v-progress-circular indeterminate color="main"> </v-progress-circular>
                </div>
                <div class="mt-6">
                    <span>Отчет может формироваться около 1 минуты</span>
                </div>
            </div>
        </div>
        <div v-show="!loading">
            <v-row class="ma-0">
                <v-col cols="9">
                    <v-row class="ma-0">
                        <v-menu
                            ref="datesMenu"
                            v-model="dateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <BaseInputOutlined
                                    class="filter"
                                    outlined
                                    dense
                                    :hide-details="true"
                                    v-model="dateRangeText"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></BaseInputOutlined>
                            </template>
                            <v-date-picker v-model="dates" no-title scrollable range color="main">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="datesMenu = false"> Отмена </v-btn>
                            </v-date-picker>
                        </v-menu>
                        <PhoneFilter
                            class="filter"
                            style="max-width: 160px; min-width: 160px"
                            v-model="phoneFilter"
                            @submit="setInitialData(false)"
                            v-if="dashboardElements.includes('filter:phone')"
                        >
                        </PhoneFilter>

                        <CustomActivatorSelect
                            key="filter:productDisplay"
                            class="filter"
                            :items="productDisplayFilterValues"
                            v-model="productDisplayFilter"
                            :multiple="false"
                        >
                            <template v-slot:activator="{ selected, _id }">
                                <BaseInputOutlined
                                    hide-details
                                    class="select-input"
                                    append-icon="mdi-menu-down"
                                    :id="_id"
                                    label="ОТОБРАЖЕНИЕ ПРОДУКТОВ"
                                    :boldPlaceholder="true"
                                    readonly
                                    hideDetails
                                    style="width: 260px;"
                                >
                                </BaseInputOutlined>
                            </template>
                        </CustomActivatorSelect>

                        <CustomActivatorSelect
                            key="filter:communicactionChannel"
                            class="filter"
                            :items="communicactionChannelFilterValues"
                            v-model="communicactionChannelFilter"
                            :multiple="true"
                        >
                            <template v-slot:activator="{ selected, _id }">
                                <BaseInputOutlined
                                    hide-details
                                    class="select-input"
                                    append-icon="mdi-menu-down"
                                    :id="_id"
                                    :label="`СПОСОБ СВЯЗИ(${selected.length})`"
                                    :boldPlaceholder="true"
                                    readonly
                                    hideDetails
                                >
                                </BaseInputOutlined>
                            </template>
                        </CustomActivatorSelect>
                        <CustomActivatorSelect
                            v-for="filter in filters"
                            :key="filter.value"
                            class="filter"
                            v-model="selectedFilterValues[filter.value]"
                            :items="filterValuesList[filter.value]"
                            :multiple="true"
                            itemName="option"
                        >
                            <template v-slot:activator="{ selected, _id }">
                                <BaseInputOutlined
                                    hide-details
                                    class="select-input"
                                    append-icon="mdi-menu-down"
                                    :id="_id"
                                    :label="`${String(filter.title).toUpperCase()}(${selected.length})`"
                                    :boldPlaceholder="true"
                                    readonly
                                    hideDetails
                                >
                                </BaseInputOutlined>
                            </template>
                        </CustomActivatorSelect>
                    </v-row>
                </v-col>
                <v-col cols="3" align-end>
                    <div style="display: flex; justify-content: flex-end">
                        <v-btn color="primary" elevation="0" class="ma-3" @click="setInitialData(false)"
                            >Применить</v-btn
                        >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                    elevation="0"
                                    class="ma-3 outlined-btn"
                                    @click="setInitialData(true)"
                                    style="
                                        width: 36px;
                                        max-width: 36px;
                                        min-width: 36px;
                                        padding: 0 !important;
                                    "
                                    ><v-icon>mdi-filter-remove-outline</v-icon></v-btn
                                >
                            </template>
                            <span class="white--text">Сбросить фильтры</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
            <StatisticsTable
                v-for="table in statisticsTables"
                :key="table.fieldValue"
                :chartData="table.data"
                :header="table.header"
                :fieldText="table.fieldText"
                :fieldValue="table.fieldValue"
                :hintId="table.hintId"
            />

            <CommunicationList
                v-if="dashboardElements.includes('table:communicationList')"
                :chartData="dashboardData.communication_list_data"
                :connector="connector"
            ></CommunicationList>
            <Quiz v-if="!loading" quiz-id="analytics-1" :delay="10000" />
            <p style="white-space: pre-line" class="mt-12" v-if="isAdmin">
                {{ ownerReport }}
            </p>
        </div>
    </div>
</template>

<script>
import * as t from '@/store/chisai/GCB2/actionTypes'
import fb from '@/plugins/firebase'
import ResultValues from '@/components/chisai/GCB2/analytics/adminReport/ResultValues.vue'
import CommunicationList from '@/components/chisai/GCB2/analytics/adminReport/charts/CommunicationList.vue'
import StatisticsTable from '@root/src/components/chisai/GCB2/analytics/adminReport/charts/StatisticsTable.vue'
import { getAdminReportData } from '@root/src/api/chisai/GCB2'
import BaseInputOutlined from '@root/src/components/inputs/BaseInputOutlined.vue'
import PhoneFilter from '@root/src/components/chisai/GCB2/taskList/PhoneFilter.vue'
import cfg from '../../../../config'
import { getOwnerReport } from '@root/src/api/admin'
import Quiz from '@root/src/components/quiz/quiz.vue'
import CustomActivatorSelect from '@/components/inputs/CustomActivatorSelect.vue'
import { mapFilterToObjectFormat } from '@root/src/utils'
export default {
    name: 'GCB2Analytics',
    components: {
        ResultValues,
        CommunicationList,
        StatisticsTable,
        BaseInputOutlined,
        PhoneFilter,
        Quiz,
        CustomActivatorSelect,
    },
    props: {
        sectionName: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        projectType: null,
        loading: false,
        dateMenu: false,
        filterValuesList: {},
        selectedFilterValues: {},
        phoneFilter: '',
        productDisplayFilter: 1,
        productDisplayFilterValues: [
            { id: 1, name: 'Только услуги' },
            { id: 2, name: 'Услуги и товары, купленные с услугами' },
            { id: 3, name: 'Все услуги и товары' },
        ],
        communicactionChannelFilterValues: [
            { id: 'phone', name: 'Звонок' },
            { id: 'mess', name: 'Сообщение' },
            { id: 'no', name: 'Нет информации о типе связи' },
        ],
        communicactionChannelFilter: ['phone', 'mess', 'no'],
        dates: [],
        dateFormat: 'DD.MM.YYYY',
        activationsList: [],
        dashboardData: [],
        ownerReport: '',
        allFilters: [
            {
                title: 'Клиент',
                value: 'client_name_list',
                type: 'select',
                id: 'filter:client',
            },
            {
                title: 'Статус',
                value: 'extended_status_name_list',
                type: 'select',
                id: 'filter:extendedStatus',
            },
            {
                title: 'Пользователь',
                value: 'user_name_list',
                type: 'select',
                id: 'filter:user',
            },
            {
                title: 'Активация',
                value: 'activation_name_list',
                type: 'select',
                id: 'filter:activation',
            },
            {
                title: 'Филиал атрибуции клиента',
                value: 'project_attribution_name_list',
                type: 'select',
                id: 'filter:projectAttribution',
            },
            {
                title: 'Филиал коммуникации',
                value: 'project_communication_name_list',
                type: 'select',
                id: 'filter:projectCommunication',
            },
            {
                title: 'Теги',
                value: 'tag_list',
                type: 'select',
                id: 'filter:tagList',
                alwaysEnabled: true,
            },
            /* {
                title: 'Действие',
                value: 'type_of_action_list',
                type: 'select',
            }, */
        ],
    }),
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector() {
            return this.project.connectors.find(con => con.connectorType === 'GCB2')
        },
        dateRangeText() {
            const orderedDates = [this.getMinDate(), this.getMaxDate()]
            const formatedDates = orderedDates.map(date => this.moment(date).format(this.dateFormat))
            return formatedDates.join(' - ')
        },
        isAdmin() {
            return cfg.adminsWhitelist.includes(this.$auth.user.sub)
        },
        filters() {
            return this.allFilters.filter(el => el.alwaysEnabled || this.dashboardElements.includes(el.id))
        },
        dashboardElements() {
            return this.connector?.analyticsSettings?.elements ?? this.dashboardData?.default_elements ?? []
        },
        allStatisticsTables() {
            return [
                {
                    header: 'Статистика в разрезе сотрудников',
                    fieldText: 'Cотрудник',
                    fieldValue: 'user_name',
                    data: this.dashboardData.stat_by_user_data,
                    id: 'table:statisticsStaff',
                },
                {
                    header: 'Статистика в разрезе активаций',
                    fieldText: 'Активация',
                    fieldValue: 'activation_name',
                    data: this.dashboardData.stat_by_activation_data,
                    id: 'table:statisticsActivation',
                },
                {
                    header: 'Статистика в разрезе способа связи',
                    fieldText: 'Способ связи',
                    fieldValue: 'action_comm_type_name',
                    data: this.dashboardData.stat_by_comm_type,
                    id: 'table:statisticsCommType',
                },
                {
                    header: 'Статистика в разрезе филиалов коммуникации',
                    fieldText: 'Филиал',
                    fieldValue: 'project_communication_name',
                    data: this.dashboardData.stat_by_project_communication_data,
                    id: 'table:statisticsProjectCommunication',
                },
                {
                    header: 'Статистика в разрезе атрибуции клиентов',
                    fieldText: 'Филиал',
                    fieldValue: 'project_attribution_name',
                    data: this.dashboardData.stat_by_project_attribution_data,
                    id: 'table:statisticsProjectAtribution',
                },
            ]
        },
        statisticsTables() {
            return this.allStatisticsTables.filter(
                el => el.alwaysEnabled || this.dashboardElements.includes(el.id)
            )
        },
    },
    watch: {
        'project.timestamps.communicationUpdate': {
            immediate: true,
            handler(val) {
                this.setInitialData(true)
            },
        },
    },
    methods: {
        async setInitialData(removeFilters) {
            this.loading = true
            if (removeFilters) {
                this.setDefaultDates()
                this.filterValuesList = {}
                this.selectedFilterValues = {}
                this.phoneFilter = ''
            }
            const { data, error } = await getAdminReportData({
                projectId: this.project.id,
                filters: this.getRequestFilters(),
            })
            if (error) {
                this.$store.dispatch('callNotify', 'При загрузке данных произошла ошибка ', { root: true })
            }
            this.projectType = data.projectType
            if (this.isAdmin) {
                this.ownerReport = await getOwnerReport({
                    projectId: this.project.id,
                    dateLimits: {
                        min_date: this.getMinDate().format('YYYY-MM-DD'),
                        max_date: this.getMaxDate().format('YYYY-MM-DD'),
                    },
                })
            }

            this.dashboardData = data
            this.setFilterValues()
            this.loading = false
        },

        getMinDate() {
            if (this.dates.length === 1) {
                return this.moment(this.dates[0])
            }
            const date1 = this.moment(this.dates[0])
            const date2 = this.moment(this.dates[1])
            if (date1 < date2) {
                return date1
            } else {
                return date2
            }
        },
        getMaxDate() {
            if (this.dates.length === 1) {
                return this.moment(this.dates[0])
            }
            const date1 = this.moment(this.dates[0])
            const date2 = this.moment(this.dates[1])
            if (date1 > date2) {
                return date1
            } else {
                return date2
            }
        },
        setFilterValues() {
            for (const filter of this.filters) {
                const filterValue = filter.value
                this.filterValuesList[filterValue] = Object.keys(this.dashboardData[filterValue]).map(key =>
                    Object.assign({}, this.dashboardData[filterValue][key], {
                        id: key,
                        selected: true,
                    })
                )
                this.selectedFilterValues = Object.assign({}, this.selectedFilterValues, {
                    [filterValue]: Object.keys(this.dashboardData[filterValue])
                        .map(key => Object.assign({}, this.dashboardData[filterValue][key], { id: key }))
                        .filter(el => el.selected)
                        .map(el => el.id),
                })
            }
        },
        getRequestFilters() {
            const allRequestFilters = {}
            for (const filterValue in this.filterValuesList) {
                const filterList = [...this.filterValuesList[filterValue]]
                const selectedFilters = filterList.filter(el =>
                    this.selectedFilterValues[filterValue].includes(el.id)
                )
                const unSelectedFilters = _.differenceBy(filterList, selectedFilters, el => el.id).map(el =>
                    Object.assign({}, el, { selected: false })
                )
                if (filterValue === 'tag_list' && unSelectedFilters.length === filterList.length) {
                    allRequestFilters[filterValue] = { state: 'ALL', data: [] }
                } else if (filterValue !== 'tag_list' && unSelectedFilters.length === 0) {
                    allRequestFilters[filterValue] = { state: 'ALL', data: [] }
                } else {
                    const requestFilters = selectedFilters.concat(unSelectedFilters)
                    allRequestFilters[filterValue] = { state: 'SOME', data: requestFilters }
                }
            }
            allRequestFilters.phone = this.phoneFilter
            allRequestFilters.products_display = this.productDisplayFilter
            allRequestFilters.type_of_comm_list = mapFilterToObjectFormat(
                this.communicactionChannelFilter,
                this.communicactionChannelFilterValues
            )
            allRequestFilters.min_date = this.getMinDate().format('YYYY-MM-DD')
            allRequestFilters.max_date = this.getMaxDate().format('YYYY-MM-DD')
            return allRequestFilters
        },
        setDefaultDates() {
            const dateFormat = 'YYYY-MM-DD'
            const yesterday = this.moment()
            const endDate = yesterday.clone()
            const startDate = yesterday.clone().subtract(28, 'days')
            this.dates = [startDate.format(dateFormat), endDate.format(dateFormat)]
        },

        getActivationWithGroup(item) {
            return `${item.activationName}/${item.activationGroup}`
        },
    },

    mounted() {},
    destroyed() {},
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.deleted
    color: red
.contact
    color: #1d9e00
.changed
    color: #fbbc04
.filter
    margin: 12px
    max-width: 230px

::v-deep .recomendations-table thead th
    background-color: #0000000B !important
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content
    box-shadow: none

::v-deep .admin-report-table th
    font-size: 12px !important
::v-deep .admin-report-table th span
    color: $gray
::v-deep .admin-report-table td span, td
    font-size: 14px !important
::v-deep .admin-report-table td
    vertical-align: top
    padding-top: 8px !important
::v-deep .admin-report-table th
    vertical-align: top
    padding-top: 8px !important
::v-deep .admin-report-table .summary-row
    vertical-align: top
    background-color: $light-gray-5
    font-weight: 600
    padding-top: 8px !important
::v-deep .v-data-footer
    border: none
::v-deep .col
    padding: 0
</style>
