import { Connector } from "@root/src/types/main";

export default function (connectors : Connector[]) {
    let newConnectors = connectors.slice(0)
    const disabledWaBasisConnector = connectors.find(item => item.connectorType === "whatsappBasis" && !item?.alive)
    if (disabledWaBasisConnector) {
        newConnectors = connectors.filter(item => item.connectorType !== "whatsappBasis")
    }
    return newConnectors
}
