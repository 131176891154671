<template>
    <div>
        <v-row class="ma-0" align="center">
            <h2 class="dashboard my-10">{{ header }}</h2>
            <HintIcon :hintId="hintId" class="ml-2" v-if="hintId !== null"></HintIcon>
        </v-row>
        <v-data-table :headers="headers" :items="processedData" class="admin-report-table fixed-table">
            <template v-slot:[`item.visit_and_record_count`]="{ item }">
                <span>{{
                    getFieldWithConversion(
                        item,
                        'visit_and_record_count',
                        'visit_and_record_count_conversion'
                    )
                }}</span>
            </template>
            <template v-slot:[`item.visit_count`]="{ item }">
                <span> {{ getFieldWithConversion(item, 'visit_count', 'visit_count_conversion') }}</span>
            </template>
            <template slot="body.append">
                <tr class="summary-row">
                    <td>ОБЩИЙ ИТОГ</td>
                    <td class="text-right">{{ summary.communication_count }}</td>
                    <td class="text-right">{{ summary.postponedAndBannedCount }}</td>
                    <td class="text-right">
                        {{
                            getFieldWithConversion(
                                summary,
                                'visit_and_record_count',
                                'visit_and_record_count_conversion'
                            )
                        }}
                    </td>
                    <td class="text-right">{{ summary.fact_plus_plan_revenue }}</td>
                    <td class="text-right">
                        {{ getFieldWithConversion(summary, 'visit_count', 'visit_count_conversion') }}
                    </td>
                    <td class="text-right">{{ summary.fact_revenue }}</td>
                    <td class="text-right">{{ summary.plan_revenue }}</td>
                    <td class="text-right">{{ summary.canceled_count }}</td>
                    <td class="text-right">{{ summary.lost_revenue }}</td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { sumBy, isNil } from 'lodash'
import { numberWithSpaces } from '@/utils'
import HintIcon from '@root/src/components/HintIcon.vue'
const numSort = (a, b) => {
    const aNum = a ? Number(a.toString().replace(/\s/g, '')) : 0
    const bNum = b ? Number(b.toString().replace(/\s/g, '')) : 0
    return aNum - bNum
}

export default {
    name: 'GCB2StatisticsTable',
    components: { HintIcon },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
        fieldValue: {
            type: String,
        },
        fieldText: {
            type: String,
        },
        header: {
            type: String,
        },
        hintId: {
            type: String,
            default: null
        },
    },
    data: () => ({}),
    computed: {
        headers() {
            return [
                { text: this.fieldText, value: this.fieldValue, width: '150px' },
                ,
                { text: 'Кол-во контактов', value: 'communication_count', align: 'right' },
                { text: 'Отложили / Не напоминаем', value: 'postponedAndBannedCount', align: 'right' },
                {
                    text: 'Запись или посещение после ком-ции',
                    value: 'visit_and_record_count',
                    align: 'right',
                },
                {
                    text: 'Выручка от записи или посещения после ком-ции',
                    value: 'fact_plus_plan_revenue',
                    align: 'right',
                    sort: numSort,
                },
                { text: 'Посещение после ком-ции', value: 'visit_count', align: 'right', sort: numSort },
                {
                    text: 'Выручка от посещения после ком-ции',
                    value: 'fact_revenue',
                    align: 'right',
                    sort: numSort,
                },
                {
                    text: 'Разница между записями и посещениями',
                    value: 'plan_revenue',
                    align: 'right',
                    sort: numSort,
                },
                { text: 'Отм. записи', value: 'canceled_count', align: 'right', sort: numSort },
                { text: 'Сумма по отм. записям', value: 'lost_revenue', align: 'right', sort: numSort },
            ]
        },
        summary() {
            return {
                communication_count: sumBy(this.chartData, 'communication_count'),
                postponedAndBannedCount: `${sumBy(this.chartData, 'postponed_count')} / ${sumBy(
                    this.chartData,
                    'ban_count'
                )}`,
                visit_and_record_count: sumBy(this.chartData, 'visit_and_record_count'),
                visit_and_record_count_conversion:
                    sumBy(this.chartData, 'visit_and_record_count') /
                        sumBy(this.processedData, 'communication_count') || 1,
                fact_plus_plan_revenue: numberWithSpaces(
                    Math.round(sumBy(this.chartData, 'fact_plus_plan_revenue'))
                ),
                visit_count: numberWithSpaces(Math.round(sumBy(this.chartData, 'visit_count'))),
                visit_count_conversion:
                    sumBy(this.chartData, 'visit_count') / sumBy(this.processedData, 'communication_count') ||
                    1,
                fact_revenue: numberWithSpaces(Math.round(sumBy(this.chartData, 'fact_revenue'))),
                plan_revenue: numberWithSpaces(Math.round(sumBy(this.chartData, 'plan_revenue'))),
                canceled_count: numberWithSpaces(Math.round(sumBy(this.chartData, 'canceled_count'))),
                lost_revenue: numberWithSpaces(Math.round(sumBy(this.chartData, 'lost_revenue'))),
            }
        },
        processedData() {
            return this.chartData.map(el => ({
                [this.fieldValue]: el[this.fieldValue],
                communication_count: el.communication_count,
                postponedAndBannedCount: `${el.postponed_count} / ${el.ban_count}`,
                visit_and_record_count: el.visit_and_record_count,
                visit_and_record_count_conversion: el.visit_and_record_count / (el.communication_count || 1),
                fact_plus_plan_revenue: numberWithSpaces(Math.round(el.fact_plus_plan_revenue)),
                visit_count: numberWithSpaces(Math.round(el.visit_count)),
                visit_count_conversion: el.visit_count / (el.communication_count || 1),
                fact_revenue: numberWithSpaces(Math.round(el.fact_revenue)),
                plan_revenue: numberWithSpaces(Math.round(el.plan_revenue)),
                canceled_count: numberWithSpaces(Math.round(el.canceled_count)),
                lost_revenue: numberWithSpaces(Math.round(el.lost_revenue)),
            }))
        },
    },
    methods: {
        getFieldWithConversion(item, field, conversionField) {
            if (isNil(item[field])) {
                return ''
            } else {
                return `${item[field]} (${(Number(item[conversionField]) * 100).toFixed(0)}%)`
            }
        },
    },
    created() {},
}
</script>
<style lang="sass" scoped>
::v-deep .fixed-table table
    table-layout: fixed
    word-break: break-all
::v-deep .fixed-table table th
    padding: 0 12px !important
::v-deep .fixed-table table td
    padding: 0 12px !important
</style>
