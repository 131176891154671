import { useProject } from '@root/src/hooks/useProject'
import { ManualCommunication } from '@root/src/types/GCB2'
import { computed } from 'vue'

export default function() {
    const waBtnDisabled = computed(() => {
        const project = useProject()
        const connectors = project.project.value.connectors
        const oldBot = connectors.find(connectors => connectors.connectorType === 'whatsapp')
        const newBot = connectors.find(connectors => connectors.connectorType === 'whatsappBasis')
        if (oldBot && oldBot?.alive) return false
        if (newBot && newBot?.alive) return false
        return true
    })

    const findError = (communications: ManualCommunication[] | ManualCommunication) => {
        const toShortPhoneError = () => {
            if (Array.isArray(communications)) {
                return communications.some(item => item.hrefInfo[0].phone.replace(/\D/g, '').length < 11)
            } else {
                return communications.hrefInfo[0].phone.replace(/\D/g, '').length < 11
            }
        }

        if (waBtnDisabled.value) return 'Бот Whatsapp отключен'
        if (toShortPhoneError()) {
            if (Array.isArray(communications)) {
                return 'Один из номеров телефонов длиной меньше 11 цифр'
            } else {
                return 'Номер телефона короче 11 цифр'
            }
        }
    }

    return {
        findError,
    }
}
