import cfg from '../../config'
import { getInstance } from '../auth'
import { ApiWrapper } from '../types/main'
import { getError } from '../utils'

export async function getQuiz(quizId: string): Promise<ApiWrapper<{ show: boolean; quiz: any } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/quiz`)
    const options = {
        method: 'GET',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
    }
    const params: any = { quizId, userId: auth.user.sub }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
export async function quizSaveAnswers({
    quizId,
    preference,
    answers,
}: {
    quizId: string
    preference: any
    answers: any
}): Promise<ApiWrapper<{ show: boolean; quiz: any } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = new URL(`${cfg.apiEndpoint}/quiz/answers`)
    const options = {
        method: 'POST',
        headers: { 'content-type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
            quizId,
            userId: auth.user.sub,
            preference,
            answers,
        }),
    }
    const res = await fetch(url.toString(), options)
    if (res.status > 300) {
        const error = await getError(res)
        return { data: null, error }
    }
    const data = await res.json()
    return { data, error: null }
}
