
import Vue, { PropType } from 'vue'
import { getModa, saveCustomModa, setChangedModaNotification } from '../../../../api/chisai/GCB2'
import TextField from '../../../inputs/TextField.vue'
import SpinnerLoader from '../../../SpinnerLoader.vue'
import { isInteger } from 'lodash'
import { checkRole } from '../../../../helpers/roles'
import { Project } from '../../../../types/main'

export default Vue.extend({
    components: { TextField, SpinnerLoader },
    props: {
        value: { type: Boolean as PropType<boolean> },
        item: { type: Object as PropType<any> },
    },
    data: () => ({
        loading: true as boolean,
        saveLoading: false as boolean,
        moda: [] as Array<any> | null,
        modaRules: [(v: any) => isInteger(Number(v)) || 'Цикл должен быть целым числом'],
        changedModa: [] as Array<any>,
        validationErrorsCount: 0 as number,
    }),
    computed: {
        headers(): any[] {
            return [
                {
                    text: 'Название услуги',
                    value: 'serviceTitle',
                    sortable: false,
                },
                {
                    text: 'Общий цикл, нед.',
                    value: 'standartModa',
                    sortable: false,
                    showCondition: () => this.checkRole(this.project.id, 'gcb2:tasklist:moda:general:view'),
                },
                {
                    text: 'Персональный цикл, нед.',
                    value: 'clientModa',
                    sortable: false,
                    showCondition: () => this.checkRole(this.project.id, 'gcb2:tasklist:moda:personal:view'),
                },
            ].filter(el => (el.showCondition ? el.showCondition() : true))
        },
        isDialog: {
            get(): boolean {
                return this.value
            },
            set(value: boolean): void {
                this.$emit('input', value)
            },
        },
        project(): Project {
            const projectId = this.$router.currentRoute.params.id
            return this.$store.getters.projectById(projectId)
        },
        saveBtnDisabled(): boolean {
            return (
                this.validationErrorsCount > 0 ||
                (!this.checkRole(this.project.id, 'gcb2:tasklist:moda:general:save') &&
                    !this.checkRole(this.project.id, 'gcb2:tasklist:moda:personal:save'))
            )
        },
    },
    watch: {
        isDialog() {
            this.initData()
        },
    },
    methods: {
        checkRole,
        handleValidationError(err: boolean) {
            if (err) {
                this.validationErrorsCount++
            } else {
                this.validationErrorsCount--
            }
        },
        async initData() {
            this.loading = true
            this.validationErrorsCount = 0
            this.changedModa = []
            const moda = (await getModa({ projectId: this.item.projectId, clientId: this.item.clientId }))
                .data
            this.moda = moda
            this.loading = false
        },
        changeModa(e: string, item: any, type: 'personal' | 'standart') {
            const clientModa = type === 'personal' ? Number(e) || null : null
            const standartModa = type === 'standart' ? Number(e) || null : null
            const index = this.changedModa.findIndex(
                (el: any) =>
                    el.type === type &&
                    el.scenario === item.scenario &&
                    el.serviceTittle === item.serviceTittle
            )
            if (index < 0) {
                this.changedModa.push({
                    clientModa: clientModa,
                    standartModa: standartModa,
                    scenario: item.scenario,
                    serviceTittle: item.serviceTittle,
                    type: type,
                })
            } else {
                const itemToUpdate = [...this.changedModa][index]
                const updatedItem = Object.assign(itemToUpdate, { clientModa, standartModa })
                this.changedModa?.splice(index, 1)
                this.changedModa?.push(updatedItem)
            }
        },
        async saveModa() {
            this.saveLoading = true
            const res = await Promise.all(
                this.changedModa.map(el =>
                    saveCustomModa({
                        projectId: this.item.projectId,
                        scenario: el.scenario,
                        customModa: el.type === 'personal' ? el.clientModa : el.standartModa,
                        clientId: el.type === 'personal' ? this.item.clientId : null,
                        type: el.type,
                    })
                )
            )
            this.saveLoading = false
            if (res.some((el: any) => el.error)) {
                this.$store.dispatch('callNotify', 'Ошибка')
            } else {
                await setChangedModaNotification({
                    projectId: this.item.projectId,
                    active: true,
                })
                this.isDialog = false
            }
        },
    },
})
