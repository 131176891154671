
import Vue, { PropType } from 'vue'
import { Project } from '@/types/main'

import _, { findIndex } from 'lodash'

export default Vue.extend({
    components: {},

    data: () => ({}),

    methods: {
        goToCreateActivation(activationId: number) {
            this.$router.push(`${this.$router.currentRoute.path}/activation`)
        },
    },

    mounted() {},
})
