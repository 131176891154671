
import { computed, defineComponent, ref } from 'vue'
import { useGcb2Store } from '@/store/stores/gcb2/gcb2'
export default defineComponent({
    setup() {
        const gcb2Store = useGcb2Store()

        const isActive = computed({
            get: () => gcb2Store.tasklistErrorModalIsActive,
            set: v => gcb2Store.setTasklistErrorModalIsActive(v),
        })
        const confirmClick = () => gcb2Store.setTasklistErrorModalIsActive(false)
        return { gcb2Store, isActive, confirmClick }
    },
})
